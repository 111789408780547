@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~ngx-toastr/toastr-bs4-alert';
@import 'custom-gdx-ui-styles.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'variables.scss';
@import '~highlight.js/styles/atom-one-light.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'buttons.scss';
@import './styles/custom-nz-element-styles.scss';

:root {
  --primaryColor: #{$primary-100};
}

.mat-tooltip {
  background: $grey-800 !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-color: $grey-300;
}

.mat-checkbox-frame,
.mat-checkbox-background {
  border-radius: 3px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $btn-p-enabled !important;
  border-radius: 3px !important;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.block-windows {
  position: fixed;
  background: $black;
  opacity: 0.5;
  z-index: 3000;
  cursor: wait;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

button:focus {
  outline: none;
}

.menu-item {
  color: $grey-600;
  font-size: 14px;
}

.menu-item.active {
  background-color: $grey-300;
  color: $grey-900;
  font-size: 14px;
}

div.mat-step-icon {
  height: 32px !important;
  width: 32px !important;
}

.document-wizard,
#edit-sign-request {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  mat-step-header {
    background: none !important;
  }

  mat-horizontal-stepper.no-margin {
    margin: 16px -16px 0 -16px !important;

    .mat-horizontal-stepper-content {
      margin: 8px 32px !important;
    }
  }

  div.mat-horizontal-stepper-header-container {
    height: 136px !important;
  }

  div.mat-horizontal-content-container {
    padding-top: 0rem !important;
  }

  .mat-horizontal-stepper-header-container {
    padding: 0 50px;
  }

  gdx-datepicker {
    input.gdx-form-control,
    input.gdx-form-control {
      height: 32px;
    }
  }

  gdx-select {
    div.ng-select-container {
      min-height: 32px;
      height: 32px !important;
    }
  }
}

.input-group {
  flex-direction: inherit;

  input.gdx-input.form-control {
    border-radius: 3px;
    width: 100%;

    &:hover {
      box-shadow: 0 0 0 1px $grey-400 !important;
    }

    &:focus {
      border: 1px solid $grey-600 !important;
      box-shadow: none !important;
    }
  }
}

/*!--- Interfirma Calendario y Selector de Hora CSS ---->*/

.cdk-overlay-container {
  max-height: 100%;
  /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;

  .cdk-overlay-dark-backdrop {
    position: fixed;
  }

  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      padding: 50px 0;
    }
  }
}

.scaleModalSign {
  max-width: inherit !important;

  &.auto-heigth {
    height: auto !important;
  }
}

.scaledModal {
  width: 90% !important;
  max-width: inherit !important;

  &.auto-heigth {
    height: auto !important;
  }

  @include media-breakpoint-up(lg) {
    width: 70% !important;
  }

  // Responsive styles for devices with screens size 1200 and above
  @include media-breakpoint-up(xl) {
    width: 500px !important;

    &.stroke-modal {
      width: 350px !important;
    }
  }
}

// Start modal overwrites
// Modal overflow
#edit-sign-request {
  overflow: visible;
}

// End modal overwrites
// start text-transform
.capitalize {
  text-transform: capitalize;
}

.uppercase-first-letter {
  &::first-letter {
    text-transform: uppercase;
  }
}

// end text-transform
.update-success {
  input {
    animation-duration: 0.5s;
    animation-name: updateSuccess;
    animation-iteration-count: 3;
  }
}

.border-circle {
  overflow: visible;
}

@keyframes updateSuccess {
  0% {
    border-color: $grey-200;
  }

  50% {
    border-color: $alt-30;
  }

  100% {
    border-color: $grey-200;
  }
}

.mat-menu-panel {
  min-height: unset !important;
}

.mat-menu-panel.dashboard-custompopover {
  background-color: $grey-100;
}

.mat-menu-child-container {
  margin-left: 230px;

  &.closed {
    margin-left: 52px;
  }

  .mat-menu-content {
    border-radius: 3px !important;
    background-color: $grey-200 !important;
    padding: 8px !important;

    .separator {
      width: 100%;
      border: 1px solid $grey-900;
      margin: 4px 0;

      &:last-child {
        border: none;
      }
    }

    .mat-menu-child-item {
      border-radius: 3px;
      padding: 8px;
      color: $grey-600;
      font-weight: 500;
      display: flex;
      justify-content: left;

      &:hover {
        border-radius: 3px;
        background-color: $grey-100;
      }
    }
  }
}

.mat-menu-child-container.btn-items {
  .mat-menu-content {
    background-color: $primary-100 !important;
  }

  .mat-menu-child-item {
    color: $white;
    padding-left: 0;

    &:hover {
      border-radius: 3px;
      background-color: $primary-100;
    }
  }
}

// Start Atomic design CSS
.if-mr-24 {
  margin-right: 24px;
}

.if-mr-16 {
  margin-right: 16px;
}

.if-mr-8 {
  margin-right: 8px;
}

.if-mr-4 {
  margin-right: 4px;
}

.if-ml-4 {
  margin-left: 4px;
}

.if-divider {
  height: 1px;
  background-color: $grey-300;
  margin: 15px;
}

gdx-select {
  div.ng-select-container {
    min-height: 32px !important;
    height: 32px !important;
    border: 1px solid $grey-600 !important;
    box-shadow: none !important;
  }
}

.gdx-clickable {
  cursor: pointer;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  max-height: 100%;
  overflow: auto;
  pointer-events: all;
}

// Banners start
.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family;
  font-size: 14px;
  letter-spacing: -0.5px;
  color: $grey-900;

  &.banner-overall {
    position: fixed;
    z-index: 999;
    top: 0px;
  }

  &.banner-small {
    height: 24px;
  }

  &.banner-big {
    height: 64px;
  }

  &.info {
    background-color: $alt-17;
  }

  &.warning {
    background-color: $yellow;
  }
}

.search-document {
  mat-option {
    padding: 0px;
    margin: 0 0 2px 0;
    height: 32px;

    &:last-of-type {
      margin: 0;
    }

    span.mat-option-text {
      padding: 0px;
      margin: 0px;
      height: 32px;

      .search-document-row {
        display: flex;
        align-items: center;
        height: 32px;

        &.suggestion {
          padding-left: 8px;

          .did-you-mean {
            font-size: 12px;
            color: $grey-600;
          }

          .suggestion {
            margin-left: 8px;
            font-weight: 400;

            &::first-letter {
              text-transform: uppercase;
            }
          }
        }

        .status-color {
          height: 100%;
          width: 8px !important;
          margin-right: 8px;
        }
      }
    }
  }
}

//TODO: Do not remove properties affects electronic signature modal
.scaleModalSign {
  max-width: inherit !important;

  &.auto-heigth {
    height: auto !important;
  }

  &.stroke-modal {
    min-width: inherit !important;
  }
}

@media (max-width: 768px) {
  .custom-dialog-container {
    margin-right: 0 !important;
  }
}

.interfirma-button {
  appearance: none;
  border-radius: 3px;
  border: none;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.interfirma-button.primary {
  color: $white;
  background-color: $btn-p-enabled;
  border-color: $btn-p-enabled;
}

.interfirma-button.primary:hover {
  color: $white;
  background-color: $btn-p-hover;
  border-color: $btn-p-hover;
}

.interfirma-button.primary:disabled {
  background: $btn-p-disabled;
  border-color: $btn-p-disabled;
  color: $grey-400;
  pointer-events: none;
}

.literax-tooltip {
  &.literax-tooltip-signature-quote {
    max-width: 225px;
  }

  .tooltip-inner {
    max-width: inherit !important;
    background-color: $grey-800;
    overflow: hidden;
  }

  .arrow::before {
    border-top-color: $grey-800;
    border-bottom-color: $grey-800;
  }

  .sign-tooltip {
    width: 370px;
    padding: 15px;

    text-align: left;
    position: relative;

    &::before {
      font-family: 'Font Awesome 5 Pro', sans-serif;
      position: absolute;
      content: '\f672';
      top: 5px;
      left: -15%;
      font-size: 70px;
      color: $grey-600;
      opacity: 0.3;
      z-index: 1;
    }

    h1 {
      color: $white;
      font-size: 14px;
      padding: 0px 0px 5px;
      border-bottom: 4px solid $btn-p-enabled;
      display: inline-block;
      z-index: 2;
      position: relative;
    }

    p {
      font-weight: 300;
      font-size: 12px;
      padding: 0px;
      z-index: 2;
      position: relative;
    }
  }
}

// ---- sign modal ----

.sign-modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-dropdown-menu-item {
  .ant-avatar {
    margin-right: 0.25rem;
    .ant-avatar-lg-string,
    .ant-avatar-sm-string,
    .ant-avatar-string {
      position: inherit;
      left: auto;
    }
  }
}

span.nbsp {
  &:before {
    content: '\00a0';
  }
}

.ant-btn.ant-btn-block {
  text-align: left !important;
}

.ant-modal-footer .custom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-events-popover {
  .popover-body {
    padding: 0;
  }
}

.attachments-read-modal {
  .ant-modal-content {
    background-color: transparent;
    .ant-modal-close {
      top: 10px;
      right: 10px;
      .ant-modal-close-x {
        height: 15px;
        width: 15px;
        font-size: inherit;
        line-height: 1;
      }
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.button {
    border-radius: 3px;
    border-style: none !important;
    padding: 6px 7px;
    font-size: 14px;
    font-weight: 400;
}

.button--primary {
    background-color: $btn-p-enabled !important;
    color: $white !important;
    &:hover {
        background-color: $btn-p-hover !important;
    }
    &:disabled {
        background: $btn-p-disabled !important;
        color: $grey-400 !important;
        pointer-events: none !important;
    }
}

.button--secondary {
    background-color: $btn-s-enabled !important;
    color: $grey-800 !important;
    &:hover {
        background-color: $btn-s-hover !important;
    }
    &:disabled {
        background: $btn-s-disabled !important;
        color: $grey-400 !important;
        pointer-events: none !important;
    }
}

  
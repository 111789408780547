@use 'sass:math';
@import '_constants';

/*** Basicos ***/
$black: #000000;
$white: #ffffff;

/*** Cliente ***/
$customer: #424242;

/*** Marca ***/
$brand-01: #ef0027;
$brand-02: #b80027;
$main-bg: #f0f2f5;

/*** Primarios ***/
$primary-120: #151b26;
$primary-100: #2a364d;
$primary-80 : #3f5173;
$primary-60 : #6b7b99;
$primary-40 : #a3adbf;
$primary-20 : #dadee6;

$primary-colors-arr: (120: $primary-120,
  100: $primary-100,
  80: $primary-80,
  60: $primary-60,
  40: $primary-40,
  20: $primary-20);

$brand-colors: ('brand': $brand-01,
  'brand-hover': $brand-02);

/*** Grises ***/
$grey-900: #212121;
$grey-800: #424242;
$grey-700: #616161;
$grey-600: #757575;
$grey-500: #9e9e9e;
$grey-400: #bdbdbd;
$grey-300: #e0e0e0;
$grey-200: #eeeeee;
$grey-100: #f5f5f5;
$grey-000: #fafafa;

/*** Enviados ***/
$sent-100     : #2a364d;
$sent-80      : #3f5173;
$sent-60      : #6b7b99;
$sent-40      : #a3adbf;
$sent-g700    : #616161;
$mat-tab-label: #8d98b2;
/*** Recibidos ***/
$received-100 : #2a364d;
$received-80  : #3f5173;
$received-60  : #6b7b99;
$received-40  : #a3adbf;
$received-g700: #616161;

/*** Pagares ***/
$promissory-notes-100 : #2a364d;
$promissory-notes-80  : #3f5173;
$promissory-notes-60  : #6b7b99;
$promissory-notes-40  : #a3adbf;
$promissory-notes-g700: #616161;
$promissory-notes-g500: #9e9e9e;

/*** Plantillas ***/
$templates-100 : #2a364d;
$templates-40  : #a3adbf;
$templates-g700: #616161;
$templates-g500: #9e9e9e;

/*** Botones Primarios ***/
$btn-p-enabled : #1976d2;
$btn-p-hover   : #2196f3;
$btn-p-focused : #0d47a1;
$btn-p-disabled: #f5f5f5;

/*** Botones Secundarios ***/
$btn-s-enabled : #eeeeee;
$btn-s-hover   : #f5f5f5;
$btn-s-focused : #e0e0e0;
$btn-s-disabled: #f5f5f5;

/*** Alternativos ***/
$alt-01: #e57373;
$alt-02: #90a4ae;
$alt-03: #ffb74d;
$alt-04: #7986cb;
$alt-05: #ff8a65;
$alt-06: #81c784;
$alt-07: #a1887f;
$alt-08: #fff176;
$alt-09: #64b5f6;
$alt-10: #9575cd;
$alt-11: #ffd54f;
$alt-12: #4db6ac;
$alt-13: #dce775;
$alt-14: #ba68c8;
$alt-15: #4dd0e1;
$alt-16: #f06292;
$alt-17: #4fc3f7;
$alt-18: #aed581;
$alt-19: #ced4da;
$alt-20: #162852;
$alt-21: #bbdefb;
$alt-22: #515d71;
$alt-23: #cbcbcb;
$alt-24: #495057;
$alt-25: #7a7a7a;
$alt-26: #e63917;
$alt-27: #f3f5f7;
$alt-28: #c8c8c8;
$alt-29: #e8eaef;
$alt-30: #56f356;
$alt-31: #d1d6e0;
$alt-32: #2f54eb;
$alt-33: #f0f0f0;
$alt-34: #D9D9D9;
$alt-35: #ff4d4f;
$alt-36: #595959;
$alt-37: #f5f5dc;
$alt-38: #1890ff;
$alt-39: #FAAD14;
$alt-40: #595959;
$alt-41: #52C41A;
$alt-42: #E6F7FF;
$alt-43: #91D5FF;
$alt-44: #00000073;
$alt-45: #1890FC;
$alt-46: #FFFBE6;
$alt-47: #FFE58F;


// review colors 
$alt-48: #F9F0FF;
$alt-49: #722ED1;
// approval colors
$alt-50: #E6FFFB;
$alt-51: #13C2C2;

$alt-52: #F0F2F5;
$alt-53:#F0F0F0;
$alt-54: #BFBFBF;

/*** Secundarios ***/
$sec-a-100: #ffab00;
$sec-a-20 : #fff7e6;
$sec-b-100: #e63917;
$sec-b-20 : #ffebee;
$sec-c-100: #1b5e20;
$sec-c-20 : #e8f5e9;
$sec-d-100: #0d47a1;
$sec-d-20 : #e3f2fd;

//Reports
$approval: #27b898;
$review  : #8a59b3;
$sign    : #2f6ec6;

$shadow-alt-35: #ff4d4f33;

@function luminance($color) {
  $red  : nth($linear-channel-values, red($color) + 1);
  $green: nth($linear-channel-values, green($color) + 1);
  $blue : nth($linear-channel-values, blue($color) + 1);

  @return .2126 * $red+.7152 * $green+.0722 * $blue;
}

@function contrast($back, $front) {
  $backLum: luminance($back) + .05;
  $foreLum: luminance($front) + .05;

  @return math.div(max($backLum, $foreLum), min($backLum, $foreLum));
}

@function choose-contrast-color($color) {
  $lightContrast: contrast($color, white);
  $darkContrast : contrast($color, black);

  @if ($lightContrast > $darkContrast) {
    @return white;
  }

  @else {
    @return black;
  }
}


// extend from gdx removed library
/*** Main colors ***/
$primary-color: var(--primaryColor);
$primary-color-dark: var(--primaryDark);
$primary-color-light: var(--primaryLight);
$gradient-color: var(--gradientColor);
$primary-color-if: #1b3266;
$primary-color-dark-if: #162852;
$primary-color-light-if: #203c7a;
$text-color: #8d98b2;
$text-color-2: #9db8f2;
$content-text-color: var(--textColor);
$primary-text-color: var(--primaryTextColor);
$secondary-text-color: var(--secondaryTextColor);
$profile-email-color: var(--profileEmailColor);
$profile-hover-color: var(--profileHoverColor);
$background-color: var(--backgroundColor);
$toggle-button-color: var(--toggleButtonColor);
$card-color: var(--cardColor);
$row-color: var(--rowColor);
$black-color: #000000;
$white-color: #ffffff;
$grey-color: #484848;
$calendar-success-color: #e8fde7;
$title-color: #4d4d4d;
$ui-color: #808080;
$ui-color-2: #e6e6e6;
$ui-color-3: #f2f2f2;
$ui-color-4: #d1d6e0;
$ui-color-5: #f2f4f7;

/*** Status bar colors ***/
$kicker-color: #79cc21;
$blue-dark-color: #216980;
$blue-bar-color: #42d3ff;
$blue-light-color: #a0e9ff;
$blue-lighter-color: #d9f6ff;
$yellow-color: #fce067;
$orange-color: #fab26b;
$orange-dark-color: #ff8568;
$alert-color: #e63917;

/*** Blue variations ***/
$blue-medium-color: #2e58b3;
$blue-medium-color-2: #3c71e6;

/*** Green variations ***/
$kicker-light-color: #f3fce9;

/*** Color variations ***/
$white-color-dark-50: darken($white-color, 50%);
$white-color-dark-20: darken($white-color, 20%);

/*** Layout ***/
$border-color: var(--borderColor);
$border: 1px solid $border-color;
$border-radius: 3px;
$default-padding: 0.625rem;
$spacer-x-x-small: 0.25rem; /* 4px */
$spacer-x-small: 0.5rem; /* 8px */
$spacer-m-small: 0.75rem; /* 12px */
$spacer-small: 1rem; /* 16px */
$spacer-medium-small: 1.25rem; /* 20px */
$spacer-medium: 1.5rem; /* 24px */
$spacer-medium-large: 1.75rem; /* 28px */
$spacer-large: 2rem; /* 32px */
$spacer-m-large: 2.25rem; /* 36px */
$spacer-x-large: 2.5rem; /* 40px */
$spacer-xx-large: 2.75rem; /* 44px */
$spacer-xxx-large: 3rem; /* 48px */
$spacer-4x-large: 3.5rem; /* 56px */

/*** Sidebar ***/
$sidebar-width: 280px;
$sidebar-width-collapsed: 80px;

/*** Font ***/
$font-small: 12px;
$font-size: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-medium-large: 20px;
$font-size-dialog-header: 22px;
$header-font-size: 24px;
$font-size-xlarge: 30px;
$font-size-xx-large: 36px;
$font-size-xxx-large: 48px;
$font-family: 'Roboto', sans-serif;
$light-font-weight: 100;
$font-weight: 400;
$bold-font-weight: 500;
$top-bold-font-weight: 900;
$normal-font-weight: normal;
$default-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.3);

$default-transition: all 0.2s ease;

/** Buttons State Colors **/
$color-hover: #284c99;
$color-activated: #0d1933;
$disabled-color: #f3f5f7;
$disabled-text-color: #8d98b2;


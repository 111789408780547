@import './variables';
@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 2px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 12px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-size: 12px;
  font-weight: 600;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 12px;
  color: $grey-600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:hover,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:hover,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: $grey-200;
}

.ng-dropdown-panel .scroll-host {
  border: 2px solid transparent !important;
}

.ng-select.events-container-tags {
  .ng-select-container {
    max-height: inherit;
    height: inherit !important;
  }
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border: 1px solid $grey-200 !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: $grey-200 !important;
  border-radius: 3px;
  overflow: hidden;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid $btn-p-enabled !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: $white;
  background-color: $btn-p-enabled;
  &:hover {
    background-color: $btn-p-focused;
  }
}

.ng-select .ng-select-container {
  height: 32px !important;
  min-height: 32px;
}

.ng-select.hide-arrow {
  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-select {
  .ng-select-container {
    color: $grey-800 !important;
  }
  &.ng-touched.ng-invalid {
    border-color: red;
  }
  &:not(.ng-invalid):hover {
    box-shadow: 0 0 0 1px #d1d6e0;
  }
  &:not(.ng-invalid).ng-select-focused {
    box-shadow: 0 0 1px var(--primaryColorDark);
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  color: $grey-800 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: $grey-600 !important;
}
